import { Grow } from '@mui/material';

export const getAppSnackbarInfos = () => {
    const transitionCustom = (props) => {
        return <Grow {...props} />;
    };
    return {
        transitionCustom,
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center'
        }
    };
};
