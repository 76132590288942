import { useMediaQuery } from '@mui/material';

const DESKTOP_BREAKPOINT = 1280;
const TABLET_BREAKPOINT = 768;

const useWindowSize = () => {
    const isDesktop = useMediaQuery(`(min-width:${DESKTOP_BREAKPOINT}px)`);
    const isMobile = useMediaQuery(`(max-width:${TABLET_BREAKPOINT - 1}px)`);
    const isTablet = !isDesktop && !isMobile;

    return {
        isDesktop,
        isTablet,
        isMobile
    };
};
export default useWindowSize;
